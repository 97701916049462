import { getLogInvestmentAmount } from '../numbersUtils';
import { Charts, Log, isLegacyLog } from '../types';

export const TARGET_RISK_COLORS = {
  domain: ['1', '1.6', '2', '2.4', '3', '4', '5', '6', '7'],
  range: [
    '#5CC569',
    '#91D89A',
    '#74DB81',
    '#CD5B45',
    '#FFC832',
    '#F5A623',
    '#F08A23',
    '#F45B5B',
    '#E64444',
  ],
};

const TARGET_VOL_COLORS = {
  domain: ['1', '2', '3', '4', '5', '6', '7'],
  range: ['#5CC569', '#74DB81', '#FFC832', '#F5A623', '#F08A23', '#F45B5B', '#E64444'],
};

export const portfolioAmountDimensionFilter = (log: Log) => {
  if (isLegacyLog(log)) {
    const amount = log.portfolio_amount ?? 1 + getLogInvestmentAmount(log);

    const threshholds = [0, 1, 5, 20, 80];
    const maxIndex = threshholds.findIndex((t) => amount < t * 1000);
    if (maxIndex > 0) {
      return `${threshholds[maxIndex - 1]}k - ${threshholds[maxIndex]}k`;
    }
    return '>80k€';
  }
  return '0€';
};

export const userAgeDimensionFilter = (log: Log) => {
  if (isLegacyLog(log)) {
    if (log.age_user) {
      const threshholds = [0, 20, 35, 50, 65, 80];
      const maxIndex = threshholds.findIndex((t) => log.age_user!! <= t);
      if (maxIndex > 0) {
        return `${threshholds[maxIndex - 1]}-${threshholds[maxIndex]}`;
      }
      return '81 et +';
    }
  }
  return 'Inconnu';
};

const isPortfolioRealDimensionFilter = (log: Log): string => {
  if (isLegacyLog(log)) {
    switch (log.portfolio_is_real) {
      case true:
        return 'Reel(true)';
      case false:
        return 'Virtuel(false)';
      default:
        return 'Simulation(null)';
    }
  }
  return 'Simulation(null)';
};

export const BDDFCharts: Charts[] = [
  {
    name: 'pieChart',
    logKey: 'with_esg_profile',
    title: 'ESG Profile',
  },
  {
    name: 'pieChart',
    logKey: 'channel',
    title: 'Channel',
  },
  {
    name: 'pieChart',
    logKey: 'target_risk',
    title: 'Target Risk',
    colors: TARGET_RISK_COLORS,
  },
  {
    name: 'pieChart',
    logKey: 'with_esg_customization',
    title: 'ESG customization',
  },
  {
    name: 'pieChart',
    logKey: 'portfolio_amount',
    title: 'Portfolio Amount',
    dimensionFilter: portfolioAmountDimensionFilter,
  },
  {
    name: 'pieChart',
    logKey: 'contract_id',
    title: 'Contract',
    height: 300,
  },
  {
    name: 'pieChart',
    logKey: 'a_category',
    title: 'ESG A Category',
    defaultDimensionName: 'No ESG',
  },
  {
    name: 'pieChart',
    logKey: 'status',
    title: 'Status',
  },
  {
    name: 'pieChart',
    logKey: 'age_user',
    dimensionFilter: userAgeDimensionFilter,
    title: 'User Age',
  },
  {
    name: 'pieChart',
    logKey: 'b_category',
    title: 'ESG B Category',
    defaultDimensionName: 'No ESG',
  },
  {
    name: 'pieChart',
    logKey: 'is_already_optimized',
    title: 'Already Optimized',
  },
  {
    name: 'pieChart',
    logKey: 'error_type',
    defaultDimensionName: 'No Error',
    title: 'Error Type',
    width: 400,
  },
  {
    name: 'pieChart',
    logKey: 'c_category',
    title: 'ESG C Category',
    defaultDimensionName: 'No ESG',
  },
  {
    name: 'pieChart',
    logKey: 'minimum_share',
    title: 'ESG Minimum Share',
    defaultDimensionName: 'No ESG',
  },
  {
    name: 'pieChart',
    logKey: 'with_esg_not_respected_flag',
    title: 'ESG Not Respected',
  },
  {
    name: 'rowChart',
    logKey: 'http_route',
    title: 'Route',
    height: 275,
    withLabel: true,
  },
  {
    name: 'rowChart',
    logKey: 'preselection',
    title: 'Top 10 selection',
    height: 320,
    withDataGrouping: true,
  },
];

export const BPFCharts: Charts[] = [
  {
    name: 'pieChart',
    logKey: 'target_sd',
    title: 'Target Vol',
    colors: TARGET_VOL_COLORS,
  },
  {
    name: 'pieChart',
    logKey: 'contract_id',
    title: 'Contract',
  },
  {
    name: 'pieChart',
    logKey: 'status',
    title: 'Status',
  },
  {
    name: 'pieChart',
    logKey: 'portfolio_is_real',
    dimensionFilter: isPortfolioRealDimensionFilter,
    title: 'Is Portfolio Real',
  },
  {
    name: 'pieChart',
    logKey: 'mandate_type',
    title: 'Mandate Type',
  },
  {
    name: 'pieChart',
    logKey: 'is_already_optimized',
    title: 'Already Optimized',
  },
  {
    name: 'pieChart',
    logKey: 'error_type',
    defaultDimensionName: 'No Error',
    title: 'Error Type',
  },
  {
    name: 'pieChart',
    logKey: 'risk_profile_name',
    title: 'Risk Profile Name',
  },
  {
    name: 'pieChart',
    logKey: 'client_universe',
    title: 'Geographical Zone',
  },
  {
    name: 'rowChart',
    logKey: 'http_route',
    title: 'Route',
    height: 275,
    withLabel: true,
  },
];

export const DigitalClubsCharts: Charts[] = [
  {
    name: 'pieChart',
    logKey: 'with_esg_profile',
    title: 'ESG Profile',
  },
  {
    name: 'pieChart',
    logKey: 'target_risk',
    title: 'Target Risk',
    colors: TARGET_RISK_COLORS,
  },
  {
    name: 'pieChart',
    logKey: 'portfolio_amount',
    title: 'Portfolio Amount',
    dimensionFilter: portfolioAmountDimensionFilter,
  },
  {
    name: 'pieChart',
    logKey: 'contract_id',
    title: 'Contract',
  },
  {
    name: 'pieChart',
    logKey: 'status',
    title: 'Status',
  },
  {
    name: 'pieChart',
    logKey: 'is_already_optimized',
    title: 'Already Optimized',
  },
  {
    name: 'pieChart',
    logKey: 'error_type',
    defaultDimensionName: 'No Error',
    title: 'Error Type',
  },
  {
    name: 'pieChart',
    logKey: 'client_universe',
    title: 'Theme',
  },
  {
    name: 'rowChart',
    logKey: 'http_route',
    title: 'Route',
    height: 275,
    withLabel: true,
  },
];

export const HBBDCharts: Charts[] = [
  {
    name: 'pieChart',
    logKey: 'status',
    title: 'Satus',
  },
  {
    name: 'pieChart',
    logKey: 'error_type',
    title: 'Error Type',
  },
];

export const MFAAASCharts: Charts[] = [
  {
    name: 'pieChart',
    logKey: 'target_volatility',
    title: 'Target Volatility',
    colors: TARGET_VOL_COLORS,
  },
  {
    name: 'pieChart',
    logKey: 'distributor_name',
    title: 'Distributor',
    defaultDimensionName: 'bcef',
  },
  {
    name: 'pieChart',
    logKey: 'with_esg_interest',
    title: 'ESG Interest',
  },
  {
    name: 'pieChart',
    logKey: 'esg_not_respected',
    title: 'ESG Not Respected',
  },
  {
    name: 'pieChart',
    logKey: 'portfolio_cannot_be_optimized',
    title: 'Portfolio Cannot Be Optimized',
  },
  {
    name: 'pieChart',
    logKey: 'client_universe',
    title: 'Geographical Zones',
  },
  {
    name: 'pieChart',
    logKey: 'status',
    title: 'Status',
  },
  {
    name: 'pieChart',
    logKey: 'mandate_type',
    title: 'Mandate Type',
  },
  {
    name: 'pieChart',
    logKey: 'error_type',
    defaultDimensionName: 'No Error',
    title: 'Error Type',
    width: 400,
  },
  {
    name: 'empty',
    logKey: 'empty',
  },
  {
    name: 'rowChart',
    logKey: 'http_route',
    title: 'Route',
    height: 275,
    withLabel: true,
  },
];

export const MFAAASV2Charts: Charts[] = [
  {
    name: 'pieChart',
    logKey: 'target_volatility',
    title: 'Target Volatility',
    colors: TARGET_VOL_COLORS,
  },
  {
    name: 'pieChart',
    logKey: 'distributor_name',
    title: 'Distributor',
  },
  {
    name: 'pieChart',
    logKey: 'with_esg_interest',
    title: 'ESG Interest',
  },
  {
    name: 'pieChart',
    logKey: 'esg_not_respected',
    title: 'ESG Not Respected',
  },
  {
    name: 'pieChart',
    logKey: 'portfolio_cannot_be_optimized',
    title: 'Portfolio Cannot Be Optimized',
  },
  {
    name: 'pieChart',
    logKey: 'client_universe',
    title: 'Geographical Zones',
  },
  {
    name: 'pieChart',
    logKey: 'status',
    title: 'Status',
  },
  {
    name: 'pieChart',
    logKey: 'mandate_type',
    title: 'Mandate Type',
  },
  {
    name: 'pieChart',
    logKey: 'error_type',
    defaultDimensionName: 'No Error',
    title: 'Error Type',
    width: 400,
  },
  {
    name: 'empty',
    logKey: 'empty',
  },
  {
    name: 'rowChart',
    logKey: 'http_route',
    title: 'Route',
    height: 275,
    withLabel: true,
  },
  {
    name: 'pieChart',
    logKey: 'offer',
    title: 'Offer',
  },
];
